:root {
  --black: hsla(0, 0%, 11%, 1);
  --white: hsla(0, 0%, 98%, 1);
  --grey: hsla(0, 0%, 85%, 1);
  --dark-grey: hsla(0, 0%, 50%, 1);

  --color1: hsla(163, 66%, 75%, 1);
  --color2: hsla(147, 59%, 63%, 1);
  --color3: hsla(356, 100%, 82%, 1);
  --color4: hsla(306, 55%, 29%, 1);
  --color5: hsla(169, 100%, 93%, 1);
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Noto Color Emoji", "Apple Color Emoji",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
}

/* PWA rules */
@media all and (display-mode: standalone) {
  body {
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
}

html {
  --bg-white: hsla(0, 0%, 100%, 0.75);
  background:
    linear-gradient(45deg, var(--bg-white) 0%, var(--bg-white) 100%),
    linear-gradient(45deg, var(--color1) 0%, var(--color3) 100%);
  background-attachment: fixed;
}

a {
  color: var(--color4);
}

.visually-hidden { /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.button {
  --font-color: var(--color4);
  --background-color: var(--white);
  --border-color: var(--grey);
  --shadow-color: var(--grey);
  position: relative;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 2px solid var(--border-color);
  box-shadow: 4px 4px 0 0 var(--shadow-color);
  color: var(--font-color);
  text-align: center;
  background-color: var(--background-color);
}

.button:hover,
.button:focus {
  --border-color: var(--color3);
  --shadow-color: var(--color1);
  cursor: pointer;
}

.button:focus {
  --border-color: var(--color2);
  outline: 0;
}

.button:disabled {
  --font-color: var(--dark-grey);
  --border-color: var(--grey);
  --shadow-color: var(--grey);
  cursor: default;
}

a.button {
  text-decoration: none;
}

.topLayer {
  z-index: 100;
}
