.tool {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.label {
  display: grid;
  grid-template-areas: "icon title";
  grid-template-columns: minmax(45px, auto) 1fr;
  align-items: center;
  border: 2px solid var(--grey);
  color: var(--color4);
  background-color: var(--white);
}

.label:hover,
.label:focus {
  cursor: pointer;
  border-color: var(--color3);
}

.activeLabel,
.activeLabel:hover,
.activeLabel:focus {
  border-color: var(--color2);
  box-shadow: 4px 4px 0 0 var(--color1);
}

.radio {
  grid-area: radio;
}

.icon {
  grid-area: icon;
  padding: 6px;
  text-align: center;
  font-size: 24px;
}

.title {
  grid-area: title;
  text-transform: capitalize;
}
