.header {
  grid-area: header;
  position: relative;
  padding-top: env(safe-area-inset-top);
  text-align: center;
}

.heading {
  display: flex;
  justify-content: center;
  align-content: center;
  color: var(--color4);
}

.logo {
  width: 32px;
  height: auto;
  max-height: 38px;
  margin-right: 12px;
}

.help {
  position: absolute;
  top: 28px;
  right: 0;

  font-size: 14px;
  padding: 5px 10px;

  color: var(--black);
  background-color: var(--white);
  border-radius: 10px;
  font-weight: bold;
  box-shadow: 2px 2px 0 0 var(--color3);

  /* reset styles */
  border: none;
  margin: 0;
  width: auto;
  overflow: visible;
  line-height: normal;
  cursor: pointer;
}

@media (max-width : 499px) {
  /* Help currently just lists shortcuts which are useless on mobile */
  .help {
    display: none;
    top: 5px;
    right: 5px;
  }
}


.help:before {
  content: '🙀';
  margin-right: 5px;
}

.help:focus,
.help:hover,
.help:active {
  box-shadow: 2px 2px 0 0 var(--color2);
  outline: none;
}
