.container {
  position: fixed;
  top: 50px;
  left: 50%;
  z-index: 99;
  transform: translateX(-50%);
}

@media (max-width: 499px) {
  .edit {
    position: absolute;
    top: auto;
    bottom: 235px;
  }
}

@media (min-width: 500px) {
  .container {
    position: absolute;
    top: 0;
    right: calc(100% + 12px);
    left: auto;
    transform: translateY(-50%);
    z-index: 10;
  }
}
