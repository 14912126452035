.app {
  display: grid;
  grid-template-areas:
    "header"
    "artboard"
    "primary-tools"
    "secondary-tools"
    "footer";
  grid-template-columns: auto;
  grid-template-rows: auto;
  width: 100%;
  min-height: 100%;
}

@media (min-width: 500px) {
  .app {
    grid-template-areas:
      "primary-tools header secondary-tools"
      "primary-tools artboard secondary-tools"
      "primary-tools footer secondary-tools";
    grid-template-columns: 260px 1fr 260px;
    grid-template-rows: auto 1fr auto;
    grid-gap: 24px;
  }
}
