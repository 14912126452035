.toolbar {
  padding-left: 24px;
  padding-right: 24px;
  z-index: 1;
}

@media (min-width: 500px) {
  .toolbar {
    padding: 24px;
    background-color: hsla(0, 0%, 100%, 0.25);
  }
}

.primary {
  grid-area: primary-tools;
}

.secondary {
  grid-area: secondary-tools;
}

.heading {
  font-size: 18px;
}

@media (min-width: 500px) {
  .heading {
    margin-top: 36px;
  }
}
