.resize {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.label {
  display: inline-grid;
  grid-template-areas:
  "title title"
  "value controls";
  grid-template-columns: 1fr 36px;
  grid-gap: 0;
  color: var(--color4);
  border: 2px solid var(--color2);
  background-color: var(--white);
  box-shadow: 4px 4px 0 0 var(--color1);
}

.title {
  grid-area: title;
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;
}

.value {
  grid-area: value;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.controls {
  grid-area: controls;
  display: flex;
  flex-direction: column;
}

.width {
  font-weight: bold;
}

.button {
  padding: 3px;
  color: inherit;
  background-color: var(--color1);
  border: 0;
}

.button:hover {
  background-color: var(--color3);
  cursor: pointer;
}

.button:focus {
  outline: 0;
  background-color: var(--color4);
  color: var(--white);
  cursor: pointer;
}

/*
If we switch back to using inputs of the size
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type=number] {
  appearance:textfield;
} */
