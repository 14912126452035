.palette {
  --bg: var(--color2);
  --hover: var(--color3);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2px;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 0;
  list-style-type: none;
  background-color: var(--bg);
  border: 2px solid var(--bg);
}

@media (min-width: 500px) {
  .palette {
    grid-template-columns: repeat(4, 1fr);
  }
}

.edit {
  --bg: var(--color3);
  --hover: var(--color2);
  position: relative;
}

button.editButton {
  --border-color: var(--color3);
}

.swatch {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: var(--white);
  cursor: pointer;
}

.swatch:hover {
  outline: 2px solid var(--hover);
}

.swatch:before {
  content: '';
  display: inline-block;
  padding-bottom: 100%;
}

.picker {
  position: relative;
  --highlight: var(--color2);
}

button.showPicker {
  border-color: var(--color2);
}
